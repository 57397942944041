import useUser from '@/mixins/useUser';
import {
  ContractDetail, ContractUpdateNotes, NewContractRequest
} from '@/models/Contracts';
import {
  getAllContracts, getContract, postNewContract, addNotesToContract,
  declineContract,
  acceptContract,
  reopenContract,
  deleteContract
} from '@/services/ContractService';
import { createGlobalState, useAsyncState, useEventBus } from '@vueuse/core';
import { computed, reactive, ref } from 'vue';

const useContract = createGlobalState(() => {
  const events = useEventBus('contracts');
  const { state: allContracts } = useAsyncState(getAllContracts(), []);

  const { userId } = useUser('self');

  const newContractProperties = reactive<NewContractRequest>({
    client: undefined,
    contractPartnerId: '',
    message: ''
  });

  const openContractId = ref<string | null>(null);
  const contractDetail = ref<ContractDetail | null>(null);
  const fetchContractDetail = async (contractId: string) => {
    openContractId.value = contractId;
    contractDetail.value = await getContract(contractId);
  };

  const isUserClient = computed(() => userId.value === (contractDetail.value?.client?.user?.userId ?? contractDetail.value?.client?.company?.rootUserId ?? ''));
  const isUserContractor = computed(() => userId.value === (contractDetail.value?.contractor?.user?.userId ?? contractDetail.value?.contractor?.company?.rootUserId ?? ''));

  const setContractDocumentSigature = (documentId: string, signDocument: boolean) => {
    if (!contractDetail.value) return;
    const document = contractDetail.value.documents.find((doc) => doc.id === documentId);
    if (!document) return;
    if (isUserClient.value) document.signedByClient = signDocument;
    if (isUserContractor.value) document.signedByContractor = signDocument;
  };

  const submitNewContract = async () => {
    const contract = await postNewContract(newContractProperties);
    allContracts.value.push(contract);
    newContractProperties.client = undefined;
    newContractProperties.contractPartnerId = '';
    newContractProperties.message = '';
  };

  const submitContractNotes = async (notes: ContractUpdateNotes) => {
    if (!contractDetail.value) return;
    if (isUserContractor.value) {
      notes.contactPerson = undefined;
      notes.department = undefined;
      notes.costCenter = undefined;
    }
    await addNotesToContract(contractDetail.value.contractId, notes);
  };

  const abortContractProcess = async () => {
    if (!contractDetail.value) return;
    await declineContract(contractDetail.value.contractId);
  };

  const finalizeContractProcess = async () => {
    if (!contractDetail.value) return;
    await acceptContract(contractDetail.value.contractId);
  };

  const reopenContractProcess = async () => {
    if (!contractDetail.value) return;
    await reopenContract(contractDetail.value.contractId);
  };

  const deleteContractProcess = async () => {
    if (!contractDetail.value) return;
    await deleteContract(contractDetail.value.contractId);
  };

  return {
    allContracts,
    newContractProperties,
    submitNewContract,
    events,
    openContractId,
    contractDetail,
    isUserClient,
    isUserContractor,
    fetchContractDetail,
    submitContractNotes,
    setContractDocumentSigature,
    abortContractProcess,
    finalizeContractProcess,
    reopenContractProcess,
    deleteContractProcess
  };
});

export default useContract;
