import { render, staticRenderFns } from "./MyProjectsContractContract.vue?vue&type=template&id=57bb3415&scoped=true"
import script from "./MyProjectsContractContract.vue?vue&type=script&setup=true&lang=ts"
export * from "./MyProjectsContractContract.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./MyProjectsContractContract.vue?vue&type=style&index=0&id=57bb3415&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57bb3415",
  null
  
)

export default component.exports