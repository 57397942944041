export enum NotificationType {
  Messages = 'messages',
  Projects = 'projects',
  Talks = 'talks',
  Contracts = 'contracts',
}

export enum NotificationCountType {
  ForumMessage = 'numberOfUnreadForumMessages',
  ProjectMessage = 'numberOfUnreadProjectForumMessages',
  DirectMessage = 'numberOfUnreadPrivateMessages',

  NewProjectChanges = 'numberOfNewProjectChanges',
  NewProjectComments = 'numberOfNewProjectComments',
  NewProjects = 'numberOfNewProjects',

  NewTalkComments = 'numberOfNewTalkComments',
  NewTalks = 'numberOfNewTalks',

  NewRecommendations = 'numberOfNewRecommendations',
  NewRecommendationFeedbacks = 'numberOfNewRecommendationFeedbacks',

  NewProjectOffers = 'numberOfNewProjectOffers',
  ChangedProjectOffers = 'numberOfChangedProjectOffers',

  NewContracts = 'numberOfNewContracts',
  ChangedContracts = 'numberOfChangedContracts',
}

export const UNCLEARABLE_COUNT_TYPES:NotificationCountType[] = [
  NotificationCountType.NewRecommendations,
  NotificationCountType.NewProjectOffers,
  NotificationCountType.ChangedProjectOffers
];

export const COUNTABLE_NOTIFICATION_TYPES:NotificationType[] = [
  NotificationType.Messages,
  NotificationType.Projects,
  NotificationType.Talks,
];

export type NotificationCounts = {
  [key in NotificationCountType]?: number;
}

export interface NotificationMessages extends NotificationCounts {
  [NotificationCountType.ForumMessage]: number;
  [NotificationCountType.ProjectMessage]: number;
  [NotificationCountType.DirectMessage]: number;
}

export interface NotificationProjects extends NotificationCounts {
  [NotificationCountType.NewProjectChanges]: number;
  [NotificationCountType.NewProjectComments]: number;
  [NotificationCountType.NewProjects]: number;
  [NotificationCountType.NewRecommendations]: number;
  [NotificationCountType.NewRecommendationFeedbacks]: number;
  [NotificationCountType.NewProjectOffers]: number;
  [NotificationCountType.ChangedProjectOffers]: number;
}

export interface NotificationContracts extends NotificationCounts {
  [NotificationCountType.NewContracts]: number;
  [NotificationCountType.ChangedContracts]: number;
}

export interface NotificationTalks extends NotificationCounts {
  [NotificationCountType.NewTalkComments]: number;
  [NotificationCountType.NewTalks]: number;
}

export interface NotificationContracts extends NotificationCounts {
  [NotificationCountType.NewContracts]: number;
  [NotificationCountType.ChangedContracts]: number;
}

export interface Notifications {
  [NotificationType.Projects]: NotificationProjects;
  [NotificationType.Messages]: NotificationMessages;
  [NotificationType.Talks]: NotificationTalks;
  [NotificationType.Contracts]: NotificationContracts;
}

export interface NotificationListItem {
  count: number;
  countType: NotificationCountType;
}

export interface NotificationPanelGroup {
  label?: string;
  items: NotificationListItem[];
}

export interface NotificationPanelGroups {
  notificationType: NotificationType;
  groups: NotificationPanelGroup[];
}

export interface NotificationBarPanelItem {
  count: number;
  notificationType: NotificationType;
}
